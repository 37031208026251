import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class UserEnrollmentService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user`;
    }

    paginate(data, index) {
        let url = `${this.#api}/enrollment-list`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
            if (index)
                url = url + "&page=" + index
        } else {
            url = url + "?page=" + index
        }
        return apiService.get(url);
    }

    userEnroll() {
        let url = `${this.#api}/enrollment-user-list`;
        return apiService.get(url);
    }

    getByUser(userId) {
        let url = `${this.#api}/${userId}/enrollment`;
        return apiService.get(url);
    }

    update(userId, enrollmentId, data) {
        let url = `${this.#api}/${userId}/enrollment/${enrollmentId}`
        return apiService.put(url, data);

    }

    store(userId, data) {
        let url = `${this.#api}/${userId}/enrollment`
        return apiService.post(url, data);

    }

    show(userId, enrollmentId) {
        let url = `${this.#api}/${userId}/enrollment/${enrollmentId}`
        return apiService.get(url);
    }

    getById(enrollmentId) {
        let url = `${this.#api}/enrollment-id/${enrollmentId}`
        return apiService.get(url);
    }

    delete(userId, enrollmentId) {
        let url = `${this.#api}/${userId}/enrollment/${enrollmentId}`
        return apiService.delete(url);
    }

    enrollmentStatus(userId, enrollmentId) {
        let url = `${this.#api}/${userId}/enrollment-status/${enrollmentId}`
        return apiService.get(url);
    }

    getStats(userId) {
        let url = `${this.#api}/enrollment-stats`
        return apiService.get(url);
    }



    // convertAdToBs(data) {
    //     let url = `${this.#api}/convert-ad-to-bs`
    //     return apiService.post(url, data);
    // }
}
