// import LevelService from "@/core/services/level/LevelService";
// import ProgramService from "@/core/services/level/program/ProgramService";
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
import EssentialService from "@/core/services/enrollment/EssentialService";

const enrollmentService = new UserEnrollmentService();
const enrollmentEssentialService = new EssentialService();
// const levelService = new LevelService();
// const programService = new ProgramService();

const StepperMixin = {
    data() {
        return {
            entrance_date:null,
            pre_registration_id: null,
            email_verification: {},
            pre_registration: {
                basic_info: {
                    full_name: null,
                    email: null,
                    phone: null,
                    nationality: 'nepal',
                    dob: null,
                    gender: null,
                    country: 'nepal',
                    provience: null,
                    district: null,
                    father_name: null,
                    mother_name: null,
                    parents_contact_number: null,
                },
                educational_information: {
                    academic_backgrounds: [],
                    optional_subjects: null,
                    compulsory_subjects: null,
                    shifts: null,
                },
                other_information: {
                    hostel_facility: null,
                    transportation_facility: null,
                    recomended_sources: [],
                    photo: null,
                    marksheet: null,
                    declaration: false,
                }
            },
            pre_registration_active: false,
            levels: [],
            programs: [],
            level: {},
            program: {},
            disable_button: true,
            enrollment: {},
            educations: [],
            universities: [],
            provinces: [],
            districts: [],
            temporary_districts: [],
            municipalities: [],
            temporary_municipalities: [],
            education_backgrounds: [],
            info: {},
            entranceResult: null,
            documents: [],
            entrances: [],
            addresses: [],
            parents: [],
            documents_types: [],
            pre_program_details: {},
            program_details: [],
            isBusy: true,
            isLoading: false,
            loading: false,

            online_counselling:null,
            online_counselling_image:null,
            dob_bs_year:null,
            dob_bs_month:null,
            dob_bs_day:null,
            districtLoading: false,
            temporaryDistrictLoading: false,
            municipalityLoading: false,
            temporaryMunicipalityLoading: false,
            educational_districts:[],
            educational_municipalities:[],
            entrance_exam_validation_flag:false,
        }
    },
    computed: {
        current_enrollment() {
            return this.$store.getters.getEnrollment;
        }
    },
    methods: {
        checkMixin() {

        },
        enableSaveButton() {
            this.disable_button = false
        },
        getLevels() {
            enrollmentEssentialService.allLevels().then(response => {
                this.levels = response.data;
            });
        },
        getAllPreRegistrationLevels() {
            enrollmentEssentialService.getAllPreRegistrationLevels().then(response => {
                this.levels = response.data;
            })
        },
        getAllPreRegistrationPrograms(level) {
            this.isBusy = true;
            enrollmentEssentialService.getAllPreRegistrationPrograms(level).then(response => {
                this.programs = response.data;
            }).catch(error => {

            }).finally(() => {
                this.isBusy = false;
            })
        },
        getProgramDetail(level, program) {
            enrollmentEssentialService.getProgramDetail(level, program).then(response => {
                this.program_details = response.data.details;
            })
        },
        getAllPreRegistrationDetail(level, program) {
            enrollmentEssentialService
                .getAllPreRegistrationDetail(level, program)
                .then(response => {
                this.pre_program_details = response.data;
                    this.online_counselling=this.pre_program_details && this.pre_program_details.online_counselling ? this.pre_program_details.online_counselling:null;
                    this.online_counselling_image=this.pre_program_details &&  this.pre_program_details.online_counselling_logo_path && this.pre_program_details.online_counselling_logo_path['real']
                        ? this.pre_program_details.online_counselling_logo_path['real']: null;
                this.pre_registration_id = this.pre_program_details.id;
                this.getEntrances(this.pre_registration_id)
                if (this.current_enrollment && this.current_enrollment.id) {
                    this.buildPreRegistrationData();
                    this.$router.push({
                        name: 'pre-registration',
                        query: {application_id: this.current_enrollment.id}
                    })
                }

                if (this.pre_program_details) {
                    this.pre_registration.educational_information.academic_backgrounds = this.pre_program_details.academic_backgrounds.map(academic => {
                        let data = {
                            name: academic,
                            institute_name: null,
                            completed_year: null,
                            exam_board: academic == "see" ? "NEB/SEE" : null,
                            gpa: null,
                            symbol_no: null,
                            country:'nepal',
                            province:null,
                            district:null,
                            city:null,
                            ward:null,
                            area:null,
                        }
                        return data;
                    })
                }
                let data = {
                    level: level,
                    program: program,
                }
                this.$emit('changeProgram', data)
                this.getProgramDetail(level, program)
            })
        },

        getLevel(level_id) {
            enrollmentEssentialService.showLevel(level_id).then(response => {
                this.level = response.data.level;
                this.getPrograms(level_id);
            });
        },
        getPrograms(level_id) {
            enrollmentEssentialService.getProgramByLevelId(level_id).then(response => {
                this.programs = response.data;
            });
        },
        getProgram(program_id) {
            enrollmentEssentialService.showProgram(program_id).then(response => {
                this.program = response.data.program;
                if (this.program) {
                    this.documents_types = this.program.required_documents
                }
            });
        },
        setEnrollment(data) {
            this.$store.dispatch('setEnrollment', data)
        },
        moveToNext() {
            this.$emit('increment_step')
        },
        getEnrollmentById(enrollmentId) {
            enrollmentEssentialService
                .getInCompleteEnrollment(enrollmentId)
                .then(response => {
                    this.enrollment = response.data.enrollment;
                    this.setEnrollment(this.enrollment);
                    this.step = 3
                })
                .catch(error => {
                    //console.log(error);
                })
                .finally(() => {
                    this.overlay = false;
                });
        },
        buildPreRegistrationData() {
            this.pre_registration.basic_info.full_name = this.current_enrollment.full_name;
            this.pre_registration.basic_info.email = this.current_enrollment.email;
            this.pre_registration.basic_info.phone = this.current_enrollment.phone;

        },
        saveEnrollment(data) {
            let token = Math.random().toString(36).substring(7);
            localStorage.setItem("tk", token);
            enrollmentEssentialService
                .storeEnrollment(data)
                .then(response => {
                    this.$snotify.success("Enrolment information updated");
                    this.enrollment = response.data.enrollment;
                    if (this.enrollment) {
                        localStorage.setItem('tk', response.data.token)
                    }
                    this.setEnrollment(this.enrollment);
                    if (this.current_enrollment && this.current_enrollment.id) {
                        this.buildPreRegistrationData();
                        this.$router.push({
                            name: 'pre-registration',
                            query: {application_id: this.current_enrollment.id}
                        })
                    }
                    this.incrementStep()
                })
                .catch(error => {
                    //console.log(error);
                })
                .finally(() => {
                    this.overlay = false;
                });
        },
        updateEnrollment(data,applicantId) {
            this.loading = true;
            enrollmentEssentialService
                .updateEnrollment(applicantId, data)
                .then(response => {
                    if(response.data.status && response.data.status ==='VALIDATION_ERROR' && response.data.error_type ==='entrance_error'){
                        this.entrance_exam_validation_flag=true;
                        if(data.step==='third'){
                            this.submittedProcess();
                        }
                    }else{
                        this.entrance_exam_validation_flag=false;
                        if(data.step==='third'){
                            this.submittedProcess();
                        }
                        this.enrollment = response.data.enrollment;
                    }

                   /* this.incrementStep();*/
                })
                .catch(error => {
                    this.loading = true;
                    //console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        convertAdToBs() {
            let data = {
                dob: this.enrollment.dob
            };
            enrollmentEssentialService
                .convertAdToBs(data)
                .then(response => {
                    this.enrollment.dob_bs = response.data.dob;
                })
                .catch(error => {
                    //console.log(error);
                });
        },

        getUniversities() {
            enrollmentEssentialService.getInstitutions().then(response => {
                this.universities = response.data;
            });
        },

        getEducationBackground() {
            enrollmentEssentialService.getEducations().then(response => {
                this.educations = response.data;
            })
        },

        getProvinces() {
            enrollmentEssentialService.provinces()
                .then(response => {
                this.provinces = response.data;
            });
        },
        getDistricts(type, stateId) {
            if(type=='permanent'){
                if(stateId){
                    this.districtLoading=true;
                    enrollmentEssentialService
                        .districtsByProvince(stateId)
                        .then(response => {
                            this.districts = response.data;
                        })
                        .catch(error => {

                        })
                        .finally(() => {
                            this.districtLoading = false;
                        })
                }
            }
            if(type=='temporary'){
                if(stateId){
                    this.temporaryDistrictLoading=true;
                    enrollmentEssentialService
                        .districtsByProvince(stateId)
                        .then(response => {
                            this.temporary_districts = response.data;
                        })
                        .catch(error => {

                        })
                        .finally(() => {
                            this.temporaryDistrictLoading = false;
                        })
                }
            }



        },
        getMunicipalities(type, district_id) {
            if(type=='permanent') {
                this.municipalityLoading = true;
                enrollmentEssentialService
                    .municipalitiesByDistrict(district_id)
                    .then(response => {
                        this.municipalities = response.data;
                    })
                    .catch(error => {

                    })
                    .finally(() => {
                        this.municipalityLoading = false;
                    })
            }
            if(type=='temporary') {
                this.temporaryMunicipalityLoading = true;
                enrollmentEssentialService
                    .municipalitiesByDistrict(district_id)
                    .then(response => {
                        this.temporary_municipalities = response.data;
                    })
                    .catch(error => {

                    })
                    .finally(() => {
                        this.temporaryMunicipalityLoading = false;
                    })
            }
        },

        getEducationByLevel(levelId) {
            enrollmentEssentialService.getEducationByLevel(levelId).then(response => {
                this.education_backgrounds = response.data;

            });
        },


        userEducation(userId) {
            R
            if (!userId)
                return
            enrollmentEssentialService.userEducation(userId).then(response => {
                this.educations = response.data;
            });
        },


        questionnaireByProgram(programId) {
            if (!programId)
                return
            enrollmentEssentialService.questionnaireByProgram(programId).then(response => {
                this.info = response.data;
            });
        },


        entranceDates(programId) {
            if (!programId)
                return
            enrollmentEssentialService.entranceDatesByProgram(programId).then(response => {
                this.entrances = response.data.dates
            });
        },
        /****************Address*******************/
        getEnrollmentAddress(enrollmentId) {
            enrollmentEssentialService.getAddressesByEnrollment(enrollmentId).then(response => {
                this.addresses = response.data;
              /*  this.checkForPermanentAddress()*/
            })
        },
        saveAddress(enrollmentId, data) {
            enrollmentEssentialService.saveAddress(enrollmentId, data).then(response => {
                this.getEnrollmentAddress(enrollmentId);
            })
        },
        updateAddress(enrollmentId, id, data) {
            enrollmentEssentialService.updateAddress(enrollmentId, id, data).then(response => {
                this.getEnrollmentAddress(enrollmentId);
            })
        },
        /****************Address End *******************/

        /****************Parent *******************/
        getEnrollmentParents(enrollmentId) {
            enrollmentEssentialService.getParentsByEnrollment(enrollmentId).then(response => {
                this.parents = response.data;
                this.checkParent()
            })
        },
        saveParent(enrollmentId, data) {
            enrollmentEssentialService.saveParent(enrollmentId, data).then(response => {
                this.getEnrollmentParents(enrollmentId);
            })
        },
        updateParent(enrollmentId, id, data) {
            enrollmentEssentialService.updateParent(enrollmentId, id, data).then(response => {
                this.getEnrollmentParents(enrollmentId);
            })
        },
        /****************Parent End *******************/

        /****************Education *******************/
        getEnrollmentEducations(enrollmentId) {
            enrollmentEssentialService.getEducationsByEnrollment(enrollmentId).then(response => {
                this.educations = response.data;
                this.checkParent()
            })
        },
        saveEducation(enrollmentId, data) {
            enrollmentEssentialService.saveEducation(enrollmentId, data).then(response => {
                this.getEnrollmentEducations(enrollmentId);
                this.resetEducation();
            })
        },
        updateEducation(enrollmentId, id, data) {
            enrollmentEssentialService.updateEducation(enrollmentId, id, data).then(response => {
                this.getEnrollmentEducations(enrollmentId);
            })
        },
        /****************Education End *******************/

        /****************Info *******************/


        getInfoByEnrollment(enrollmentId) {
            enrollmentEssentialService.getInfoByEnrollment(enrollmentId).then(response => {
                this.educations = response.data;
            })
        },
        saveOrUpdateInfo(enrollmentId, data) {
            enrollmentEssentialService.saveInfo(enrollmentId, data).then(response => {
                this.getInfoByEnrollment();
                this.moveNext();
            })
        },
        /****************Info End *******************/

        /********************Document*******************/

        userDocuments(userId) {
            if (!userId)
                return
            enrollmentEssentialService.userDocuments(userId).then(response => {
                this.documents = response.data.documents;
                this.checkList();
            });
        },


        uploadDocument(enrollmentId, data) {
            this.loading = true
            enrollmentEssentialService.uploadDocument(enrollmentId, data).then(response => {
                this.userDocuments(enrollmentId);
                this.loading = false;
            }).catch(error => {
                this.loading = false;
            })
        },
        /********************Document End*******************/

        enrollmentStatus(enrollmentId, enrollment) {
            enrollmentEssentialService.enrollmentStatus(enrollmentId).then(response => {
                this.enrollment_status = response.data
            })
        },

        isPreRegistrationActive() {
            enrollmentEssentialService.IsPreRegistrationActive().then(response => {
                this.pre_registration_active = response.data.active
                this.isBusy = false;
            }).catch(error => {
            })
        },
        getEnrollmentByToken() {
            if (this.$route.query.application_id) {
                enrollmentEssentialService
                    .getInCompleteEnrollment(this.$route.query.application_id)
                    .then(response => {
                        this.enrollment = response.data.enrollment;

                        if(this.enrollment){
                            //seprating nepali date year,month and day and assigning
                            if(this.enrollment.dob_bs_year){
                                this.dob_bs_year=parseInt(this.enrollment.dob_bs_year);
                            }
                            if(this.enrollment.dob_bs_month){
                                this.dob_bs_month=parseInt(this.enrollment.dob_bs_month);
                            }
                            if(this.enrollment.dob_bs_day){
                                this.dob_bs_day=parseInt(this.enrollment.dob_bs_day);
                            }
                            if(this.enrollment.entrance_exam_id && this.pre_registration_id){
                                this.entrance_date =this.enrollment.entrance_exam_id;
                                //this.getEntranceDetail(this.pre_registration_id,this.enrollment.entrance_exam_id);
                            }
                            this.selectedProgram = this.enrollment.program_id
                            this.selectedLevel = this.enrollment.level_id
                            this.setEnrollment(this.enrollment)
                            this.buildPreRegistrationData();

                            if (this.enrollment.more_details){
                                let pre_registration = JSON.parse(response.data.enrollment.more_details);
                                this.pre_registration = JSON.parse(response.data.enrollment.more_details);
                                this.pre_registration.educational_information.academic_backgrounds=[];

                                let educational_information=null;
                                let academic_backgrounds=[];
                                if(pre_registration && pre_registration.educational_information){
                                   educational_information =  pre_registration.educational_information;
                                   educational_information.academic_backgrounds.forEach((background, index) => {
                                        background.country='nepal';
                                        if(background.province){
                                            this.getEducationalDistrictByProvince(background.province,index)
                                        }
                                        if(background.district){
                                            this.getEducationalMunicipalitiesByDistrict(background.district,index)
                                        }

                                        academic_backgrounds.push(background);
                                    });
                                    setTimeout(() => {
                                        this.pre_registration.educational_information.academic_backgrounds=academic_backgrounds;
                                    }, 1000);
                                }

                            }

                            this.dob_ad=this.enrollment.dob;
                            this.dob_bs=this.enrollment.dob_bs;
                            if(this.pre_registration.basic_info && this.pre_registration.basic_info.province){
                                this.getDistricts('permanent', this.pre_registration.basic_info.province)
                            }
                            if(this.pre_registration.basic_info && this.pre_registration.basic_info.district) {
                                this.getMunicipalities('permanent', this.pre_registration.basic_info.district)
                            }
                            if(this.pre_registration.basic_info && this.pre_registration.basic_info.temporary_province){
                                this.getDistricts('temporary', this.pre_registration.basic_info.temporary_province)
                            }
                            if(this.pre_registration.basic_info && this.pre_registration.basic_info.temporary_district) {
                                this.getMunicipalities('temporary', this.pre_registration.basic_info.temporary_district)
                            }

                        }else{
                            this.$router.push({name: 'pre-registration'});
                        }

                    }).catch(error => {
                    /*  this.$snotify.error("Application id doesn't match with existing one");*/
                    this.$router.push({name:'pre-registration'})
                    // setTimeout(()=>{
                    //     this.$router.push({name:'pre-registration'})
                    // },3000)
                })
            }
        },


        saveAndExit(id, data) {
            enrollmentEssentialService.saveAndExit(id, data).then(response => {
                this.$snotify.success('Your form has been saved. Please check email.')
                this.$router.push({name: 'saved'})
            })
        },

        verifyEmail(token1, token2) {
            this.isBusy = true;
            enrollmentEssentialService.verifyEmail({token_1: token1, token_2: token2}).then(response => {
                this.isBusy = false;
                this.email_verification = response.data
            }).catch(error => {
                this.isBusy = false;
            })
        },
        getEntrances(preRegistrationId){
            enrollmentEssentialService
                .getEntrances(preRegistrationId)
                .then(response=>{
                    this.entrances = response.data.entrances;
                })
        },
        getEntranceDetail(preRegistrationId,entranceId){
            enrollmentEssentialService
                .getEntranceDetail(preRegistrationId,entranceId)
                .then(response=>{
                    this.entrance_date = response.data.entrance;
                })
                .catch(error => {

                })
        },

        getYears()
        {
            enrollmentEssentialService.getYears().then(res=>{
                this.years = res.data.years;
            });
        },

        getMonths()
        {
            enrollmentEssentialService.getMonths().then(res=>{
                this.months = res.data.months;
            });
        },

        getDays()
        {
            enrollmentEssentialService.getDays().then(res=>{
                this.days = res.data.days;
            });
        },
        getEducationalDistrictByProvince(provinceId,index=null, changeData = false){
            if(provinceId){
                enrollmentEssentialService
                    .districtsByProvince(provinceId)
                    .then((response) => {
                        this.$set(this.educational_districts, provinceId, response.data);
                        if(changeData && this.pre_registration.educational_information.academic_backgrounds[index] && this.pre_registration.educational_information.academic_backgrounds[index].district){
                            this.pre_registration.educational_information.academic_backgrounds[index].district=null;
                        }
                        if(changeData && this.pre_registration.educational_information.academic_backgrounds[index] && this.pre_registration.educational_information.academic_backgrounds[index].city){
                            this.pre_registration.educational_information.academic_backgrounds[index].city=null;
                        }
                        if(changeData && this.pre_registration.educational_information.academic_backgrounds[index] && this.pre_registration.educational_information.academic_backgrounds[index].ward){
                            this.pre_registration.educational_information.academic_backgrounds[index].ward=null;
                        }

                        if(changeData && this.pre_registration.educational_information.academic_backgrounds[index] && this.pre_registration.educational_information.academic_backgrounds[index].area) {
                            this.pre_registration.educational_information.academic_backgrounds[index].area = null;
                        }

                    })
                    .catch((err) => {
                        this.$snotify.error("Oops something went wrong");
                    })
                    .finally(() => {

                    });
            }

        },
        getEducationalDistricts(provinceId){
            if(provinceId ){
                return this.educational_districts[provinceId] || [];
            }

        },
        getEducationalMunicipalitiesByDistrict(districtId,index=null, changeData = false){
            if(districtId){
                enrollmentEssentialService
                    .municipalitiesByDistrict(districtId)
                    .then((response) => {
                        this.$set(this.educational_municipalities, districtId, response.data);
                       /* this.$set(this.pre_registration.educational_information.academic_backgrounds, index, Object.assign({}, this.pre_registration.educational_information.academic_backgrounds[index], {
                                city: null,
                            })
                        );*/
                        if(changeData && this.pre_registration.educational_information.academic_backgrounds[index] && this.pre_registration.educational_information.academic_backgrounds[index].city){
                            this.pre_registration.educational_information.academic_backgrounds[index].city=null;
                        }
                        if(changeData && this.pre_registration.educational_information.academic_backgrounds[index] && this.pre_registration.educational_information.academic_backgrounds[index].ward){
                            this.pre_registration.educational_information.academic_backgrounds[index].ward=null;
                        }

                        if(changeData && this.pre_registration.educational_information.academic_backgrounds[index] && this.pre_registration.educational_information.academic_backgrounds[index].area) {
                            this.pre_registration.educational_information.academic_backgrounds[index].area = null;
                        }
                    })
                    .catch((err) => {
                        this.$snotify.error("Oops something went wrong");
                    })
                    .finally(() => {

                    });
            }

        },
        getEducationalMunicipalities(districtId){
            if(districtId){
                return this.educational_municipalities[districtId] || [];
            }

        },






    },

}
export default StepperMixin;
